import React from "react";
import { FaRobot } from "react-icons/fa";

const CoachInspirationMessage = ({ inspiration }) => {
  const {
    archetype,
    content_type,
    lvl_development,
    title,
    description,
    url_pic,
  } = inspiration;

  return (
    <div className="system-message-container">
      <FaRobot className="system-message-icon" />
      <div className="inspiration-message">
        <img src={url_pic} alt={title} className="inspiration-image" />
        <h1 className="inspiration-message-title">{title}</h1>
        <p>
          <strong>Description:</strong> {description}
        </p>
        <div className="inspiration-tags">
          <span className="tag archetype">{archetype.name}</span>
          <span className="tag content-type">{content_type}</span>
          <span className="tag level">{lvl_development}</span>
        </div>
      </div>
    </div>
  );
};

export default CoachInspirationMessage;

import React from 'react';
import { motion } from "framer-motion";

const FilterButtonLayout = ({ label, filterTab, setActiveTab, activeTab }) => {

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <div>
            <div className='txt-title-filter'>
                {label}
            </div>

            <div className="flex-container">
                {filterTab.map((tab) => (
                    <button
                        key={tab.id}
                        onClick={() => handleTabClick(tab.id)}
                        className={`button ${activeTab === tab.id ? "activeButton" : "hoverButton"}`}
                        style={{
                            WebkitTapHighlightColor: "transparent",
                        }}
                    >
                        {activeTab === tab.id && (
                            <motion.span
                                layoutId="bubble"
                                className="bubble"
                                transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                            />
                        )}

                        {tab.label_fr}

                    </button>
                ))}
            </div>
        </div>
    );
};

export default FilterButtonLayout;

import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import NavBarApp from "../components/NavBarApp";
import NavBarSideMenu from "../components/NavBarSideMenu";
import BeginQuestions from "../components/BeginQuestions";
import ArchetypesDashboard from "../components/ArchetypesDashboard";
import ContentDashboard from "../components/ContentDashboard";
import FavorisDashboard from "../components/FavorisDashboard";
import InspirationDashboard from "../components/InspirationDashboard";
import CoachDashboard from "../components/CoachDashboard";
import { GlobalContext } from "../GlobalContext";

import { motion } from "framer-motion";
import { FaAngleUp } from "react-icons/fa";
import { RiContractLeftFill } from "react-icons/ri";
import { RiContractRightFill } from "react-icons/ri";
import io from "socket.io-client";

const URL_API = process.env.REACT_APP_URL_API;
//const URL_API = "http://localhost:5001";
const socket = io.connect(URL_API);

const enrichStatsWithArchetypeName = (stats_archetype, infos_archetypes) => {
  return stats_archetype.map((stat) => {
    let correspondingArchetype = infos_archetypes.find(
      (archetype) => archetype._id === stat.archetype_id
    );

    if (correspondingArchetype) {
      return { ...stat, name: correspondingArchetype.archetype.name };
    }
    return stat;
  });
};

const Branding = () => {
  const {
    activeMenu,
    setActiveMenu,
    infos_archetypes,
    setInfos_Archetypes,
    stats_archetypes,
    setStats_Archetypes,
  } = useContext(GlobalContext);

  const [brandingStatut, setBrandingStatut] = useState(null);
  const [hasStatutUpdated, setHasStatutUpdated] = useState(false);
  const [userID, setUserID] = useState("");
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isSidebarReduced, setIsSidebarReduced] = useState(false);

  const handleStatusChange = (data) => {
    setBrandingStatut(data.statut);
    setUserID(data.user_id);
    setStats_Archetypes(data.stats_archetypes);
    setInfos_Archetypes(data.infos_archetypes);
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleReduceSidebar = () => {
    setIsSidebarReduced(!isSidebarReduced);
  };

  const changeActiveMenu = (newTab) => {
    setActiveMenu(newTab);
    localStorage.setItem("activeMenu", newTab);
  };

  const getUserBrandingStatut = async () => {
    let url_request = `${URL_API}/branding/statut`;
    const config = {
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.get(url_request, config);
      setBrandingStatut(response.data.statut);
      setUserID(response.data.user_id);
      setStats_Archetypes(response.data.stats_archetypes);
      setInfos_Archetypes(response.data.infos_archetypes);
      setHasStatutUpdated(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserBrandingStatut();
    const savedMenu = localStorage.getItem("activeMenu");
    if (savedMenu) {
      setActiveMenu(savedMenu);
    }
  }, []);

  useEffect(() => {
    if (hasStatutUpdated && !brandingStatut) {
      socket.emit("register", userID);
      socket.on("statusChange", handleStatusChange);
      return () => {
        socket.off("statusChange", handleStatusChange);
      };
    }
  }, [hasStatutUpdated, brandingStatut, userID]);

  return (
    <div className="body">
      <NavBarApp />
      <section className="section-app">
        <motion.div
          className="grid-app"
          animate={{
            gridTemplateColumns: isSidebarReduced ? "100px 1fr" : "350px 1fr",
          }}
          transition={{ duration: 0.2 }}
        >
          <div className="nav-side-menu ">
            <div className="btn-mobile-panel">
              <button
                className="btn-mobile-side-menu"
                onClick={handleSidebarToggle}
              >
                <motion.div
                  className="icon-side-menu white"
                  animate={{ rotate: isSidebarOpen ? 0 : 180 }}
                  transition={{ duration: 0.2 }}
                >
                  <FaAngleUp />
                </motion.div>
                <span className="btn-mobile-menu-title">Sous menu</span>
              </button>
            </div>

            <NavBarSideMenu
              brandingStatut={brandingStatut}
              changeActiveTab={changeActiveMenu}
              currentActiveTag={activeMenu}
              isSidebarOpen={isSidebarOpen}
              isSidebarReduced={isSidebarReduced}
            />

            <button
              className={`reduce-nav-side-menu ${
                isSidebarReduced ? "full-width" : ""
              }`}
              onClick={handleReduceSidebar}
            >
              {isSidebarReduced ? (
                <motion.div
                  key="contract-right"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5, ease: [0.4, 0.0, 0.2, 1] }}
                >
                  <RiContractRightFill />
                </motion.div>
              ) : (
                <motion.div
                  key="contract-left"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5, ease: [0.4, 0.0, 0.2, 1] }}
                >
                  <RiContractLeftFill />
                </motion.div>
              )}
            </button>
          </div>
          <div className="content-block">
            {brandingStatut === false && <BeginQuestions />}
            {brandingStatut === true && (
              <>
                {activeMenu === "archetypes" && (
                  <ArchetypesDashboard
                    stats_archetypes={stats_archetypes}
                    infos_archetypes={infos_archetypes}
                  />
                )}
                {activeMenu === "content" && (
                  <ContentDashboard
                    url={URL_API}
                    stats_archetype={enrichStatsWithArchetypeName(
                      stats_archetypes,
                      infos_archetypes
                    )}
                  />
                )}
                {activeMenu === "inspiration" && (
                  <InspirationDashboard
                    url={URL_API}
                    stats_archetype={enrichStatsWithArchetypeName(
                      stats_archetypes,
                      infos_archetypes
                    )}
                  />
                )}
                {activeMenu === "favoris" && (
                  <FavorisDashboard
                    url={URL_API}
                    stats_archetype={enrichStatsWithArchetypeName(
                      stats_archetypes,
                      infos_archetypes
                    )}
                  />
                )}
                {activeMenu === "coach" && <CoachDashboard url={URL_API} />}
              </>
            )}
          </div>
        </motion.div>
      </section>
    </div>
  );
};

export default Branding;

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { RiFileInfoLine } from "react-icons/ri";
import { GiMirrorMirror } from "react-icons/gi";
import { PiBrainDuotone } from "react-icons/pi";

const animation = (delay = 0.3) => ({
  hidden: {
    opacity: 0,
    x: -20,
    transition: {
      type: "spring",
      damping: 12,
      stiffness: 100,
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      damping: 12,
      stiffness: 100,
      delay,
    },
  },
});

const Icon = ({ icon }) => {
  switch (icon) {
    case 1:
      return <RiFileInfoLine style={{ marginRight: "10px" }} />;
    case 2:
      return <GiMirrorMirror style={{ marginRight: "10px" }} />;
    case 3:
      return <PiBrainDuotone style={{ marginRight: "10px" }} />;
    default:
      return null;
  }
};

const ArchetypeDescr = ({
  id,
  label,
  title,
  position,
  icon,
  descrArchetype,
}) => {
  let [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    if (descrArchetype) {
      setPageLoaded(true);
    }
  }, [descrArchetype]);

  const containerStyle = {
    marginLeft: position === "right" ? "20px" : "0px",
    marginRight: position === "left" ? "20px" : "0px",
  };

  return (
    <motion.div
      className="desc-container"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.5,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      {label && <h1 className="title-archetypes-descr">{label}</h1>}
      <div className="desc-panel">
        <motion.div
          className="txt-archetypes-descr"
          key={id}
          variants={animation(!pageLoaded ? 2.2 : 0.3)}
          initial="hidden"
          animate="visible"
        >
          <div className="title-inside-box">
            <Icon icon={icon} />
            <span className="title-desc">{title}</span>
          </div>{" "}
          <br />
          {descrArchetype}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ArchetypeDescr;

import React from 'react';


const URL = process.env.REACT_APP_URL;

const BeginQuestions = () => {
    return (
        <a href={URL + '/branding-questions'} className='box-link-questions'>
            <div className='cross-shape-container'>
                <div className='shape-horizontal-bar'>
                </div>
                <div className='shape-vertical-bar'>
                </div>
            </div>
            <span className='txt-beginning'>Commencer</span>
        </a>
    );
};

export default BeginQuestions;
import React, { useState } from "react";
import { FaMasksTheater } from "react-icons/fa6";
import { Button } from "@nextui-org/react";

const ButtonsListFilter = ({
  label,
  buttons_list,
  setActiveTab,
  activeTab,
}) => {
  const handleButtonClick = (buttonName) => {
    setActiveTab(buttonName);
  };

  const renderButtons = () => {
    let buttons = [];

    buttons.push(
      <Button
        key="all"
        className="font-bold mr-3 border border-black rounded-sm mb-5 w-[170px] sm:w-[120px] md:w-[150px] lg:w-[200px] "
        color={`${activeTab === "all" ? "secondary" : ""}`}
        variant="bordered"
        startContent={<FaMasksTheater />}
        onClick={() => handleButtonClick("all")}
      >
        All
      </Button>
    );

    if (buttons_list) {
      buttons_list.forEach((button, index) => {
        buttons.push(
          <Button
            key={index}
            className="flex items-center font-bold mr-3 border border-black rounded-sm mb-5 w-[170px] sm:w-[120px] md:w-[150px] lg:w-[200px] "
            color={`${activeTab === button.name ? "secondary" : ""}`}
            variant="bordered"
            startContent={<FaMasksTheater />}
            onClick={() => handleButtonClick(button.name)}
          >
            {button.name}
          </Button>
        );
      });
    } else {
      buttons.push(<div key="empty">Archetype introuvable</div>);
    }

    return buttons;
  };

  return (
    <div>
      <div className="txt-title-filter color-label">{label}</div>
      <div className="btn-list-container">{renderButtons()}</div>
    </div>
  );
};

export default ButtonsListFilter;

import React, { useState, useEffect } from "react";
import axios from "axios";
import SessionInspirationCard from "../components/sessionInspirationCard";

const CoachModelsToolbox = ({ url, currentModel }) => {
  const [sessions, setSessions] = useState([]);

  const getSessionsModelSelected = async () => {
    let url_request = `${url}/coach/session/selected`;

    const data = { type: currentModel.slug };
    const config = {
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(url_request, data, config);
      setSessions(response.data);
    } catch (error) {
      console.error("Error fetching sessions:", error);
    }
  };

  useEffect(() => {
    if (currentModel) {
      getSessionsModelSelected();
    }
  }, [currentModel]);

  return (
    <div>
      <div className="session-container">
        {sessions
          .filter((session) => session.model.type === "variations")
          .map((session) => (
            <SessionInspirationCard
              key={session._id}
              inspiration={session.messages[0].content.obj.inspiration}
            />
          ))}
      </div>
    </div>
  );
};

export default CoachModelsToolbox;

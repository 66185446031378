import React, { useContext } from 'react';
import { motion } from 'framer-motion';

import { LuBookmark } from "react-icons/lu";
import { GoBookmarkFill } from "react-icons/go";

import { FavoritesContext } from './FavoritesContext'; // Importez le contexte des favoris


const TAG_STYLES = {
    content_type_1: 'tag-button-content_type',
    content_type_2: 'tag-button-content_type',
    content_type_3: 'tag-button-content_type',
    content_sub_type: 'tag-button-content_sub_type',
    emotions: 'tag-button-emotions',
};

const SocialCard = ({ id, post, recommand }) => {

    const { favorites, toggleFavorite } = useContext(FavoritesContext);
    const isFavorite = favorites ? favorites.some(fav => fav === id) : false;

    const handleFavoritesClick = () => {
        toggleFavorite(id);
    };


    return (
        <motion.div
            layout
            initial={{ opacity: 0, boxShadow: "0px 0px 0px #e9e9e9" }}
            animate={{ opacity: 1, boxShadow: "0px 0px 0px #e9e9e9" }}
            exit={{ opacity: 0 }}
            whileHover={{
                translateX: "-5px",
                translateY: "-5px",
                boxShadow: "15px 18px 0px #e9e9e9"
            }}
            transition={{ duration: 0.2 }}
            className='post-card'>
            <div className='card-header'>
                <a className='infos-panel' href={post.profil_url}>
                    <div className='card-img-frame'>
                        <img src={post.profil_pic} alt="img-profil-tiktok" />
                    </div>

                    <span>{post.profil_nickname}</span>
                </a>
                <div className='infos-panel'>
                    {recommand ? <button className="tag-button">Recommander</button> : null}
                    <button onClick={handleFavoritesClick}>
                        {isFavorite ? <GoBookmarkFill className='icon-fav-social-card' /> : <LuBookmark className='icon-fav-social-card' />}
                    </button>
                </div>

            </div>

            <video
                className='card-video'
                src={post.video_content.video_link}
                style={{ width: '100%', height: 'auto', border: 'none' }}
                preload="none"
                poster={post.video_content.coverVideo}
                type="video/mp4"
                onClick={(e) => {
                    if (e.currentTarget.paused) {
                        e.currentTarget.play();
                    } else {
                        e.currentTarget.pause();
                    }
                }}>
            </video>

            <div className='card-infos'>
                <div className='tags-container'>
                    {Object.entries(post.tags).map(([key, value], index) => {
                        // if value is empty or key is not in TAG_STYLES, return null
                        if (!value || !(key in TAG_STYLES)) return null;
                        let tagClass = TAG_STYLES[key] || 'tag-button';
                        return (
                            <button key={index} className={tagClass}>{value}</button>
                        );
                    })}

                </div>
                <p>{post.video_content.descr}</p>
                <button className='card-btn'
                    onClick={() => window.open(post.video_content.url, '_blank')}>
                    Voir la publication
                </button>

            </div>
        </motion.div >
    );
};

export default SocialCard;
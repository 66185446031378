import React, { useContext, useState, useRef, useEffect } from "react";
import { GlobalContext } from "../GlobalContext";
import InspirationMessage from "../components/CoachInspirationMessage";
import ModelPanel from "../components/CoachModels";
import ModelToolboxPanel from "../components/CoachModelsToolbox";
import { FaRobot } from "react-icons/fa"; // Importer l'icône

import axios from "axios";

import { motion } from "framer-motion";

const URL_API = "http://localhost:5001";

const CoachDashboard = (url) => {
  const {
    infos_archetypes,
    stats_archetypes,
    currentSessionCoach,
    setCurrentSessionCoach,
  } = useContext(GlobalContext);

  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);

  const textareaRef = useRef(null);
  const chatPanelRef = useRef(null);
  const bottomRef = useRef(null);

  const formatSendingMessage = (msg, type, sender) => {
    return {
      sender: sender,
      type: type,
      content: {
        obj: {
          text: msg,
          obj: null,
        },
      },
    };
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() !== "") {
      const newMessage = {
        text: inputMessage,
        sender: "user",
      };

      setMessages([...messages, newMessage]);
      setInputMessage("");

      const formattedMessage = formatSendingMessage(
        inputMessage,
        "text",
        "user"
      );
      const updatedSession = { ...currentSessionCoach };
      updatedSession.messages.push(formattedMessage);
      setCurrentSessionCoach(updatedSession);

      try {
        const response = await axios
          .post(`${URL_API}/coach/session/conversations`, currentSessionCoach)
          .then(function (response) {
            setCurrentSessionCoach(response.data);
            console.log("Response post conversation");
            console.log(response);
          });
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.altKey && !e.metaKey) {
      e.preventDefault();
      handleSendMessage();
    } else if (e.key === "Enter" && (e.altKey || e.metaKey)) {
      e.preventDefault();
      setInputMessage(inputMessage + "\n");
    }
  };

  const formatReceivedMessage = (msg, sender) => {
    switch (msg.type) {
      case "Inspiration":
        return {
          inspiration: msg.content.obj,
          sender: sender,
        };
      case "text":
        return {
          text: msg.content.obj.text,
          sender: sender,
        };
      default:
        return null;
    }
  };

  const selectModelBySlug = (model) => {
    if (models.length > 0) {
      const result_model = models.find((model) => model.slug === model.type);
      if (result_model) {
        setSelectedModel(result_model.model);
        return model;
      } else {
        console.log(`Model with slug ${model.type} not found.`);
        return null;
      }
    } else {
      console.log("No models available.");
      return null;
    }
  };

  useEffect(() => {
    setMessages([]);
    if (currentSessionCoach) {
      selectModelBySlug(currentSessionCoach.model);
      const newMessages = currentSessionCoach.messages
        .map((msg) => formatReceivedMessage(msg, msg.sender))
        .filter((msg) => msg !== null);
      setMessages((prevMessages) => [...prevMessages, ...newMessages]);
    }
  }, [models, currentSessionCoach]);

  const getMessageComponent = (msg) => {
    if (msg.sender === "system" && msg.inspiration) {
      return <InspirationMessage inspiration={msg.inspiration.inspiration} />;
    } else if (msg.sender === "user") {
      return <div>{msg.text}</div>;
    } else {
      return (
        <div className="system-message-container">
          <FaRobot className="system-message-icon" />
          <div>{msg.text}</div>
        </div>
      );
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputMessage]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    getCoachModel();
  }, []);

  const getCoachModel = async () => {
    let url_request = `${url.url}/coach/get/model`;
    const config = {
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.get(url_request, config);
      setModels(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleModelClick = async (model) => {
    setSelectedModel(model);
  };

  return (
    <motion.div
      className="content-container coach-custom"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.5,
        delay: 0.5,
        ease: [0, 0.71, 0.91, 1.21],
      }}
    >
      <div className="coach-grid-structure">
        <div className="chat-wrapper">
          <div className="chat-content-panel" ref={chatPanelRef}>
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`chat-message-role ${
                  msg.sender === "user" ? "user-message" : "system-message"
                }`}
              >
                <div className="chat-message-content">
                  {getMessageComponent(msg)}
                </div>
              </div>
            ))}
            <div ref={bottomRef}></div>
          </div>
          <div className="chat-input-wrapper">
            <div className="chat-input-box">
              <textarea
                ref={textareaRef}
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                className="chat-input"
                placeholder="Une question en tête ?"
                rows="1"
              />
              <button onClick={handleSendMessage} className="send-button">
                Envoyer
              </button>
            </div>
          </div>
        </div>
        <div className="tool-container">
          <div className="coach-model-wrapper">
            <ModelPanel
              models={models}
              onModelClick={handleModelClick}
              selectedModel={selectedModel}
            />
          </div>
          <div className="coach-options-wrapper">
            <ModelToolboxPanel url={url.url} currentModel={selectedModel} />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default CoachDashboard;

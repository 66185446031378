import React from "react";

const SessionInspirationCard = ({ inspiration }) => {
  return (
    <button className="session-inspiration-card">
      <img src={inspiration.url_pic} alt={inspiration.title} />
      <h2>{inspiration.title}</h2>
      <p>{inspiration.description.substring(0, 100)}...</p>
    </button>
  );
};

export default SessionInspirationCard;

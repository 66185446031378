import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const animation = (delay = 0.3) => ({
  hidden: {
    opacity: 0,
    x: -20,
    transition: {
      type: "spring",
      damping: 12,
      stiffness: 100,
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      damping: 12,
      stiffness: 100,
      delay,
    },
  },
});

const ArchetypeSW = ({ id, initialTabs }) => {
  let [selectedTab, setSelectedTab] = useState(null);
  let [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    if (initialTabs && initialTabs.length > 0) {
      setSelectedTab(initialTabs[0]);
      setPageLoaded(true);
    }
  }, [initialTabs]);

  return (
    <motion.div
      className="sw-container sw-panel"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.5,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <nav className="sw-nav">
        {initialTabs && (
          <ul className="sw-ul">
            {initialTabs.map((tab) => (
              <li
                key={tab.id}
                className={tab === selectedTab ? "sw-li sw-selected" : "sw-li"}
                onClick={() => setSelectedTab(tab)}
              >
                {tab.label}
                {tab === selectedTab ? (
                  <motion.div className="sw-underline" layoutId="underline" />
                ) : null}
              </li>
            ))}
          </ul>
        )}
      </nav>
      <motion.main
        className="sw-main"
        key={id}
        variants={animation(!pageLoaded ? 2.2 : 0.3)}
        initial="hidden"
        animate="visible"
      >
        {selectedTab && (
          <motion.div
            key={selectedTab.id}
            variants={animation(!pageLoaded ? 2.2 : 0.3)}
            initial="hidden"
            animate="visible"
          >
            {selectedTab.content}
          </motion.div>
        )}
      </motion.main>
    </motion.div>
  );
};

export default ArchetypeSW;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Branding from "./pages/Branding";

import { NextUIProvider } from "@nextui-org/react";
import { GlobalProvider } from "./GlobalContext";

function App() {
  return (
    <NextUIProvider>
      <GlobalProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/branding" element={<Branding />} />
          </Routes>
        </BrowserRouter>
      </GlobalProvider>
    </NextUIProvider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const ArchetypeVideo = ({ currentArchetype, url }) => {
  const [embedUrl, setEmbedUrl] = useState(null);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const getVimeoEmbedUrl = (url) => {
      if (!url) return null;
      const match = url.match(/vimeo\.com\/(\d+)(#chapter=\d+)?/);
      if (match && match[1]) {
        const chapter = match[2] || "#chapter=15570176";
        return `https://player.vimeo.com/video/${match[1]}${chapter}`;
      }
      return null;
    };

    setEmbedUrl(getVimeoEmbedUrl(url));
    setKey((prevKey) => prevKey + 1);
  }, [url]);

  return (
    <motion.div
      className="archetype-video-container"
      key={currentArchetype}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.5,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      {embedUrl ? (
        <iframe
          key={key}
          src={embedUrl}
          width="100%"
          height="480"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Archetype Video"
          className="archetype-video"
        ></iframe>
      ) : (
        <p>No video available</p>
      )}
    </motion.div>
  );
};

export default ArchetypeVideo;

import React, { useContext } from "react";
import { motion } from "framer-motion";
import axios from "axios";

import { GlobalContext } from "../GlobalContext";

const InspirationCard = ({ url, inspiration }) => {
  const {
    setActiveMenu,
    inspirationData,
    setInspirationData,
    setCurrentSessionCoach,
  } = useContext(GlobalContext);

  const sessionObject = (inspiration) => {
    const session = {
      model: {
        type: "variations",
        obj_id: inspiration._id,
      },
      messages: [
        {
          sender: "system",
          type: "Inspiration",
          content: {
            text: "",
            obj: inspiration,
          },
        },
      ],
    };

    return session;
  };

  const createVariationSession = async (inspiration) => {
    try {
      let url_request = `${url}/coach/session/variation`;
      const data = sessionObject(inspiration);
      const config = {
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios
        .post(url_request, data, config)
        .then(function (response) {
          setCurrentSessionCoach(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {}
  };

  const sendDataToCoach = (tab) => {
    createVariationSession(inspiration);
    localStorage.setItem("activeMenu", tab);
    setActiveMenu(tab);
  };

  return (
    <motion.button
      layout
      initial={{ opacity: 0, boxShadow: "0px 0px 0px #e9e9e9" }}
      animate={{ opacity: 1, boxShadow: "0px 0px 0px #e9e9e9" }}
      exit={{ opacity: 0 }}
      whileHover={{
        translateX: "-5px",
        translateY: "-5px",
        boxShadow: "25px 60px 0px #e9e9e9",
      }}
      transition={{ duration: 0.2 }}
      className="inspiration-post"
      onClick={() => sendDataToCoach("coach")}
    >
      <h3 className="inspiration-archetype-title">
        {inspiration.inspiration.archetype.name}
      </h3>
      <div className="inspiration-post-background">
        <img
          className="inspiration-img"
          src={inspiration.inspiration.url_pic}
          alt=""
        />
        <div className="shape-gradiant" />
        <div className="inspiration-tag-level">
          {inspiration.inspiration.lvl_development}
        </div>
        <div className="inspiration-info-panel">
          <div className="inspiration-shape" />
          <div className="inpiration-title-panel">
            <h2 className="inspiration-title">
              {inspiration.inspiration.title}
            </h2>
          </div>
        </div>
      </div>
    </motion.button>
  );
};

export default InspirationCard;

import React, { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [infos_archetypes, setInfos_Archetypes] = useState([]);
  const [stats_archetypes, setStats_Archetypes] = useState([]);
  const [inspirationData, setInspirationData] = useState(null);
  const [currentSessionCoach, setCurrentSessionCoach] = useState(null);

  const [activeMenu, setActiveMenu] = useState("archetypes");

  return (
    <GlobalContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        infos_archetypes,
        setInfos_Archetypes,
        stats_archetypes,
        setStats_Archetypes,
        inspirationData,
        setInspirationData,
        currentSessionCoach,
        setCurrentSessionCoach,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const FavoritesContext = createContext();

export const FavoritesProvider = ({ children, baseUrl, onFavoritesUpdate }) => {
  const [favorites, setFavorites] = useState([]);

  const config = {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    axios
      .get(`${baseUrl}/user/favoris/list`, config)
      .then((response) => {
        setFavorites(response.data.data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    console.log("Favorites updated:", favorites);
  }, [favorites]);

  const toggleFavorite = (id) => {
    if (!favorites) {
      console.error("Favorites is undefined");
      return;
    }

    const isFavorite = favorites ? favorites.some((fav) => fav === id) : false;

    const newFavorites = isFavorite
      ? favorites.filter((fav) => fav !== id)
      : [...favorites, id];

    setFavorites(newFavorites);

    if (isFavorite) {
      axios
        .delete(`${baseUrl}/user/favoris/remove`, { data: { id }, ...config })
        .then((response) => {
          if (onFavoritesUpdate) {
            onFavoritesUpdate();
          }
        })
        .catch((error) => console.error(error));
    } else {
      axios
        .post(`${baseUrl}/user/favoris/add`, { id }, config)
        .then((response) => {
          if (onFavoritesUpdate) {
            onFavoritesUpdate();
          }
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <FavoritesContext.Provider value={{ favorites, toggleFavorite }}>
      {children}
    </FavoritesContext.Provider>
  );
};

import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { motion } from "framer-motion";
import CountUp from "react-countup";
import ArchetypeDescr from "./ArchetypeDescr";
import ArchetypeSW from "./ArchetypeSW";
import ArchetypeVideo from "./ArchetypeVideo";

const ArchetypesDashboard = ({ stats_archetypes, infos_archetypes }) => {
  const [width, setWidth] = useState(0);
  const [showCountUp, setShowCountUp] = useState(false);
  const [currentArchetype, setCurrentArchetype] = useState(null);
  const [descrArchetype, setDescrArchetype] = useState(null);
  const [reflectImage, setReflectImage] = useState(null);
  const [stateMind, setStateMind] = useState(null);
  const [swArchetype, setswArchetype] = useState(null);
  const [urlVideo, setUrlVideo] = useState(null);
  const archetypes_panel = useRef();
  const MAX_DELAI = 3000;

  const getStat = useCallback(
    (archetype_id) => {
      const stat = stats_archetypes.find(
        (stat) => stat.archetype_id === archetype_id
      );
      return stat ? stat.value : 0;
    },
    [stats_archetypes]
  );

  useEffect(() => {
    setWidth(
      archetypes_panel.current.scrollWidth -
        archetypes_panel.current.offsetWidth
    );
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowCountUp(true);
    }, MAX_DELAI);

    return () => clearTimeout(timeout);
  }, []);

  const sortedInfos = useMemo(() => {
    return [...infos_archetypes].sort((a, b) => {
      const aValue = getStat(a._id);
      const bValue = getStat(b._id);
      return bValue - aValue;
    });
  }, [infos_archetypes, getStat]);

  useEffect(() => {
    const firstValidInfo = sortedInfos.find((info) => getStat(info._id) > 0);
    if (firstValidInfo) {
      setCurrentArchetype(firstValidInfo._id);
    }
  }, [sortedInfos, getStat]);

  useEffect(() => {
    const infoArchetype = infos_archetypes.find(
      (info) => info._id === currentArchetype
    );
    if (infoArchetype && infoArchetype.archetype) {
      setUrlVideo(infoArchetype.archetype.url_video);
      setDescrArchetype(infoArchetype.archetype.descr);
      setReflectImage(infoArchetype.archetype.reflect_image);
      setStateMind(infoArchetype.archetype.mind_state);
      setswArchetype([
        {
          label: "Forces",
          id: "strengths",
          content: infoArchetype.archetype.strength,
        },
        {
          label: "Faiblesses",
          id: "weaknesses",
          content: infoArchetype.archetype.weakness,
        },
      ]);
    }
  }, [currentArchetype, infos_archetypes]);

  const handleClick = (archetype_id, value) => {
    if (value > 0) {
      setCurrentArchetype(archetype_id);
    }
  };

  return (
    <div className="archetype-block">
      <motion.div
        className="archetypes-panel-profile"
        ref={archetypes_panel}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.5,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <motion.div
          className="inner-panel"
          whileTap={{ cursor: "grabbing" }}
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
        >
          {sortedInfos.map((info, index) => {
            const statValue = Math.round(getStat(info._id));
            const disableClass =
              statValue === 0 ? "archetype-container--disabled" : "";

            return (
              <motion.div
                key={index}
                className={`archetypes-container ${disableClass}`}
                onClick={() => handleClick(info._id, statValue)}
                initial={{ opacity: 0 }}
                animate={{ opacity: disableClass ? 0.65 : 1 }}
                transition={{
                  duration: 1.8,
                  delay: 1.3,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
              >
                <div
                  className={`archetype-card ${
                    statValue > 0 ? "clickable" : ""
                  } ${
                    currentArchetype === info._id
                      ? ""
                      : "archetype-card-not-selected"
                  }`}
                >
                  <motion.div
                    className={`shape-archetype-circle ${
                      currentArchetype === info._id
                        ? ""
                        : "archetype-circle-not-selected"
                    }`}
                    animate={{ scale: 1 }}
                    initial={{ scale: 0 }}
                    transition={{
                      duration: 0.8,
                      delay: 2,
                      ease: [0, 0.71, 0.2, 1.01],
                    }}
                  ></motion.div>
                  <img
                    src={info.archetype.url_pic}
                    className="archetype-img"
                    alt="brand-ambassadeur"
                  />
                </div>
                <div className="archetype-title">{info.archetype.name}</div>
                <div
                  className={`affinity-panel ${
                    statValue === 0 ? "affinity-hide" : ""
                  }`}
                >
                  <div className="affinity-bar">
                    <motion.div
                      className="progress-bar"
                      initial={{ width: 0 }}
                      animate={{
                        width: `${statValue}%`,
                      }}
                      transition={{
                        duration: 1,
                        delay: 3,
                        ease: [0, 0.71, 0.2, 1.01],
                      }}
                    ></motion.div>
                  </div>
                  <div className="txt-archetypes-affinity">
                    {showCountUp ? (
                      <CountUp start={0} end={statValue} duration={1} />
                    ) : null}
                    %
                  </div>
                </div>
              </motion.div>
            );
          })}
        </motion.div>
      </motion.div>

      <div className="archetypes-wrapper-video">
        <h2 className="video-title">VIDEO</h2>
        <div className="video-panel">
          <ArchetypeVideo id={currentArchetype} url={urlVideo} />
        </div>
      </div>

      <div className="archetypes-panel-infos">
        <ArchetypeDescr
          id={currentArchetype}
          label={"description"}
          position={"left"}
          title={"description"}
          icon={1}
          descrArchetype={descrArchetype}
        />

        <ArchetypeSW id={currentArchetype} initialTabs={swArchetype} />

        <ArchetypeDescr
          id={currentArchetype}
          title={"Reflet de l'image"}
          position={""}
          icon={2}
          descrArchetype={reflectImage}
        />
        <ArchetypeDescr
          id={currentArchetype}
          label={null}
          title={"état d'esprit"}
          position={"right"}
          icon={3}
          descrArchetype={stateMind}
        />
      </div>
    </div>
  );
};

export default ArchetypesDashboard;

import React from 'react';
import Select from "react-dropdown-select";

const DropdownSelect = ({ label, options, setSelectedOptions, selectedValues }) => {

    const handleSelect = (selected) => {
        setSelectedOptions(selected);
    };

    return (
        <div>
            <div className='txt-title-filter'>
                {label}
            </div>
            <Select
                className='flex-container'
                placeholder="Selectionner un Tag"

                name='Select'
                options={options}
                labelField="label_fr"
                valueField="id"
                color='black'
                multi
                onChange={handleSelect}
                values={selectedValues}

            />

        </div>
    );
};

export default DropdownSelect;

import React, { useEffect, useState, useCallback } from "react";
import FilterButtonLayout from "./FilterButtonLayout";
import DropdownSelect from "./DropdownSelect";
import SocialCard from "./SocialCard";
import { motion } from "framer-motion";
import { FavoritesProvider } from "./FavoritesContext";

import axios from "axios";

const ARCHETYPE_TAGS = ["archetype_1", "archetype_2", "archetype_3"];
const CONTENT_TYPES = ["content_type_1", "content_type_2", "content_type_3"];
const SUB_TYPES = ["content_sub_type"];

const getTiktokPosts = async (url, page, setPopular, setPagination) => {
  let url_request = `${url}/content/posts/tiktok?page=${page}&limit=10`;

  const config = {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  await axios
    .get(url_request, config)
    .then(function (response) {
      setPopular((prev) => [...prev, ...response.data.data]);
      setPagination({
        total: response.data.total,
        totalPages: response.data.totalPages,
        currentPage: response.data.currentPage,
      });
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const getTypeAndSubTags = async (url, type, setTypeTags) => {
  let url_request = `${url}/content/tags/${type}`;

  const config = {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  await axios
    .get(url_request, config)
    .then(function (response) {
      setTypeTags(response.data.tags);
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const ContentDashboard = ({ url, stats_archetype }) => {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [typeTags, setTypeTags] = useState([]);
  const [options, setOptions] = useState([]);

  const localSelectedOptions = useCallback((newOptions) => {
    const newOptionsIds = newOptions.map((option) => option.id);
    setSelectedOptions(newOptionsIds);
    localStorage.setItem("selectedOptions", JSON.stringify(newOptionsIds));
  }, []);

  const localSelectedTab = useCallback((newActiveTab) => {
    setActiveTab(newActiveTab);
    localStorage.setItem("activeTab", newActiveTab);
  }, []);

  const selectedValues = selectedOptions
    .map((optionId) => {
      return options.find((option) => option.id === optionId);
    })
    .filter(Boolean);

  useEffect(() => {
    getTypeAndSubTags(url, "type", setTypeTags);
    getTypeAndSubTags(url, "sub", setOptions);
  }, []);

  useEffect(() => {
    getTiktokPosts(url, page, setPosts, setPagination);
    const savedSelectedOptions = localStorage.getItem("selectedOptions");
    if (savedSelectedOptions) {
      setSelectedOptions(JSON.parse(savedSelectedOptions));
    }

    const savedActiveTab = localStorage.getItem("activeTab");
    if (savedActiveTab) {
      setActiveTab(savedActiveTab);
    }
  }, [page]);

  useEffect(() => {
    let tempPosts = [...posts];

    if (activeTab !== "all") {
      tempPosts = tempPosts.filter((post) =>
        CONTENT_TYPES.some((type) => post.tiktok.tags[type] === activeTab)
      );
    }

    if (selectedOptions.length) {
      tempPosts = tempPosts.filter((post) =>
        selectedOptions.some((selectedOption) =>
          SUB_TYPES.some((type) => post.tiktok.tags[type] === selectedOption)
        )
      );
    }

    setFilteredPosts(tempPosts);
  }, [activeTab, selectedOptions, posts]);

  const loadMorePosts = () => {
    if (page < pagination.totalPages) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <FavoritesProvider baseUrl={url}>
      <div className="content-container">
        <div className="content-filter-panel">
          <FilterButtonLayout
            label="Axes stratégique"
            filterTab={typeTags}
            setActiveTab={localSelectedTab}
            activeTab={activeTab}
          />

          <DropdownSelect
            label="contexte"
            options={options}
            setSelectedOptions={localSelectedOptions}
            selectedValues={selectedValues}
          />
        </div>

        <motion.div
          className="content-posts-panel"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          {filteredPosts.map((card) => {
            const recommandation = stats_archetype
              ? stats_archetype.some((stat) =>
                  ARCHETYPE_TAGS.some(
                    (tag) => stat.name === card.tiktok.tags[tag]
                  )
                )
              : false;

            return (
              <SocialCard
                key={card._id}
                id={card._id}
                url={url}
                post={card.tiktok}
                recommand={recommandation}
              />
            );
          })}
        </motion.div>

        <motion.div className="btn-panel-loading">
          {pagination.currentPage < pagination.totalPages && (
            <motion.button
              className="btn-load"
              onClick={loadMorePosts}
              variants={{
                hover: { y: -5 },
                initial: { y: 0 },
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              whileHover="hover"
              transition={{
                type: "spring",
                stiffness: 300,
                duration: 1,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              Plus de contenu
            </motion.button>
          )}
        </motion.div>
      </div>
    </FavoritesProvider>
  );
};

export default ContentDashboard;

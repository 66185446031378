import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

const URL = process.env.REACT_APP_URL;

const DropDownProfile = forwardRef((props, ref) => {
  const handleLogout = () => {
    // Définir un cookie pour indiquer l'état de déconnexion
    document.cookie = "ask_logout=true; path=/; domain=.inception-agency.com";
    window.location.href = URL;
  };

  return (
    <div ref={ref} className="flex flex-col ">
      <ul className="flex flex-col gap-1 dropDownProfile">
        <li className="link-nav-dropDown">
          <a href={URL} className="link-nav-courses">
            <img
              src="https://uploads-ssl.webflow.com/5e5cfad3267af7c3bf7fd935/5e5cfad37cde6b2240b5cdf2_icon-home.svg"
              className="icon-nav-courses"
              alt="home-icon"
              loading="lazy"
              style={{ width: "14px" }}
            />
            <div className="txt-nav-link ">ACCUEIL</div>
          </a>
        </li>
        <li className="link-nav-dropDown">
          <a href={URL + "/formation"} className="link-nav-courses">
            <img
              src="https://uploads-ssl.webflow.com/5e5cfad3267af7c3bf7fd935/64406e969b7d2ed0522bb5d2_49944.png"
              className="icon-nav-courses"
              alt="courses-icon"
              loading="lazy"
              style={{ width: "15px" }}
            />
            <div className="txt-nav-link ">MES COURS</div>
          </a>
        </li>

        <li className="link-nav-dropDown">
          <a href={URL + "/agence"} className="link-nav-courses">
            <img
              src="https://uploads-ssl.webflow.com/5e5cfad3267af7c3bf7fd935/644072c4cf857c734ad1f6aa_ads-icon.png"
              className="icon-nav-courses"
              alt="agency-icon"
              loading="lazy"
              style={{ width: "14px" }}
            />
            <div className="txt-nav-link ">AGENCE</div>
          </a>
        </li>

        <li className="link-nav-dropDown">
          <Link to="/branding" className="link-nav-courses">
            <img
              src="https://uploads-ssl.webflow.com/5e5cfad3267af7c3bf7fd935/64a05d2bab26229b17e91c26_7398967.png"
              className="icon-nav-courses"
              alt="agency-icon"
              loading="lazy"
              style={{ width: "14px" }}
            />
            <div className="txt-nav-link ">BRANDING</div>
          </Link>
        </li>

        <li>
          <a href={URL + "/user-account"} className="link-nav-courses">
            <img
              className="icon-nav-courses"
              loading="lazy"
              width="14"
              src="https://uploads-ssl.webflow.com/5e5cfad3267af7c3bf7fd935/64407336220f0fc377e9f128_1077114.png"
              alt="nav-icon"
            />
            <div className="txt-nav-link">{"profil"}</div>
          </a>
        </li>

        <li>
          <a href="#" className="link-nav-courses" onClick={handleLogout}>
            <div className="txt-nav-link"> Déconnexion</div>
          </a>
        </li>
      </ul>
    </div>
  );
});

export default DropDownProfile;

import React, { useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import axios from "axios";

import FilterButtonLayout from "./FilterButtonLayout";
import ButtonsListFilter from "./ButtonsListFilter";
import InspirationCard from "./InspirationCard";

const getTypeAndSubTags = async (url, type, setTypeTags) => {
  let url_request = `${url}/content/tags/${type}`;

  const config = {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  await axios
    .get(url_request, config)
    .then(function (response) {
      setTypeTags(response.data.tags);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const getAllInspiration = async (url, setInspirations) => {
  let url_request = `${url}/content/inspirations`;

  const config = {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };
  await axios
    .get(url_request, config)
    .then(function (response) {
      setInspirations(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const fetchFilteredInspirations = async (
  url,
  selectedTabStrategy,
  selectedTabArchetype,
  setInspirationList,
  setIsLoading,
  setIsFirstLoad
) => {
  try {
    setIsLoading(true);
    let url_request = `${url}/content/inspirations/filtered`;

    const response = await axios.post(url_request, {
      data: [
        { content_type: selectedTabStrategy },
        { archetype: selectedTabArchetype },
      ],
    });

    setInspirationList(response.data);
  } catch (error) {
    console.error("Error fetching filtered inspirations:", error);
  } finally {
    setIsLoading(false);
    setIsFirstLoad(false);
  }
};

const InspirationDashboard = ({ url, stats_archetype }) => {
  const [typeTags, setTypeTags] = useState([]);
  const [activeTabStrategy, setActiveTabStrategy] = useState("all");
  const [activeTabArchetype, setActiveTabArchetype] = useState("all");
  const [inspirationList, setInspirationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    getTypeAndSubTags(url, "type", setTypeTags);
    getAllInspiration(url, setInspirationList);
    console.log(inspirationList);
  }, []);

  const selectedTabStrategy = useCallback(
    (newActiveTab) => {
      setActiveTabStrategy(newActiveTab);
      fetchFilteredInspirations(
        url,
        newActiveTab,
        activeTabArchetype,
        setInspirationList,
        setIsLoading,
        setIsFirstLoad
      );
    },
    [activeTabArchetype]
  );

  const selectedTabArchetype = useCallback(
    (newActiveTab) => {
      setActiveTabArchetype(newActiveTab);
      fetchFilteredInspirations(
        url,
        activeTabStrategy,
        newActiveTab,
        setInspirationList,
        setIsLoading,
        setIsFirstLoad
      );
    },
    [activeTabStrategy]
  );

  return (
    <div className="content-container">
      <motion.div
        className="inspiration-banner"
        initial={{ width: "0%" }}
        animate={{ width: "100%" }}
        transition={{
          duration: 1,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <motion.div
          className="icon-banner"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            delay: 1.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <img
            src="https://storage.googleapis.com/archetype-database/idea-guy.png"
            alt="icon-inspiration"
          />
        </motion.div>

        <motion.div
          className="inspiration-title-panel"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.8,
            delay: 1.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <div className="inspiration-wrapper">
            <h2 className="inspiration-desc">
              Trouver des nouvelles idées de contenu pour vous faire découvrir
            </h2>
          </div>
        </motion.div>

        <motion.h1
          className="inpiration-title"
          initial={{ y: "75%" }}
          animate={{ y: 0 }}
          transition={{
            duration: 0.5,
            delay: 1.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          Inspirations
        </motion.h1>
      </motion.div>

      <motion.div
        className="inspiration-filter-panel"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1,
          delay: 2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <FilterButtonLayout
          label="Axes stratégique"
          filterTab={typeTags}
          setActiveTab={selectedTabStrategy}
          activeTab={activeTabStrategy}
        />
        <ButtonsListFilter
          label="Archetypes"
          buttons_list={stats_archetype}
          setActiveTab={selectedTabArchetype}
          activeTab={activeTabArchetype}
        />
      </motion.div>

      {!isFirstLoad && isLoading ? (
        <motion.p
          className="w-full text-center text-gray-700 font-bold mt-40 text-xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          Veuillez patienter ...
        </motion.p>
      ) : !isFirstLoad && inspirationList.length === 0 ? (
        <p className="text-center text-gray-700 font-bold mt-40 text-xl ">
          Aucun concept trouvé
        </p>
      ) : (
        <motion.div
          className="inspiration-posts-panel"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1,
            delay: isFirstLoad ? 2.5 : 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          {inspirationList.map((card) => (
            <InspirationCard key={card._id} url={url} inspiration={card} />
          ))}
        </motion.div>
      )}
    </div>
  );
};

export default InspirationDashboard;

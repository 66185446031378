import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import classNames from "classnames";
import { FaTheaterMasks } from "react-icons/fa";
import { BiSolidVideos } from "react-icons/bi";
import { TbBulbFilled } from "react-icons/tb";
import { BsBookmarksFill } from "react-icons/bs";
import { FaRobot } from "react-icons/fa";

const NavBarSideMenu = ({
  brandingStatut,
  changeActiveTab,
  currentActiveTag,
  isSidebarOpen,
  isSidebarReduced,
}) => {
  const [activeTab, setActiveTab] = useState(currentActiveTag);
  const [tooltip, setTooltip] = useState({
    visible: false,
    content: "",
    position: { top: 0, left: 0 },
  });

  useEffect(() => {
    setActiveTab(currentActiveTag);
  }, [currentActiveTag]);

  const handleTabChange = (tab) => {
    if (brandingStatut || tab === "archetypes") {
      setActiveTab(tab);
      changeActiveTab(tab);
    }
  };

  const handleMouseEnter = (content, event) => {
    const position = {
      top:
        event.currentTarget.getBoundingClientRect().top + window.scrollY - 30,
      left:
        event.currentTarget.getBoundingClientRect().left +
        window.scrollX +
        event.currentTarget.offsetWidth +
        15,
    };
    setTooltip({ visible: true, content, position });
  };

  const handleMouseLeave = () => {
    setTooltip({ visible: false, content: "", position: { top: 0, left: 0 } });
  };

  const menuItems = [
    {
      key: "archetypes",
      label: "Archétypes",
      icon: FaTheaterMasks,
      tooltip: "Définissez votre image de marque",
    },
    {
      key: "content",
      label: "CONTENUS",
      icon: BiSolidVideos,
      tooltip: "Trouver des idées de contenu tendance",
    },
    {
      key: "favoris",
      label: "Favoris",
      icon: BsBookmarksFill,
      tooltip: "Contenus que vous avez sauvegardés",
    },
  ];

  return (
    <div className="test">
      <motion.h1
        key={isSidebarReduced ? "reduced" : "expanded"}
        className={`brand-title ${isSidebarOpen ? "" : "collapsed"}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 3 }}
      >
        {isSidebarReduced ? "B" : "Branding"}
      </motion.h1>

      <ul className={`side-menu ${isSidebarOpen ? "" : "collapsed"}`}>
        {menuItems.map(({ key, label, icon: Icon, tooltip }) => (
          <li key={key}>
            <Link
              to="#"
              className={classNames("link-nav-side-menu", {
                active: activeTab === key,
                disable: !brandingStatut && key !== "archetypes",
              })}
              onClick={() => handleTabChange(key)}
              onMouseEnter={(e) => handleMouseEnter(tooltip, e)}
              onMouseLeave={handleMouseLeave}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <Icon
                  className={classNames("icon-side-menu", {
                    active: activeTab === key,
                    disable: !brandingStatut && key !== "archetypes",
                  })}
                />
                {!isSidebarReduced && (
                  <div
                    className={classNames("txt-side-nav", {
                      active: activeTab === key,
                      disable: !brandingStatut && key !== "archetypes",
                    })}
                  >
                    {label}
                  </div>
                )}
              </div>
            </Link>
          </li>
        ))}
      </ul>
      {tooltip.visible && (
        <div
          className="tooltip"
          style={{ top: tooltip.position.top, left: tooltip.position.left }}
        >
          {tooltip.content}
        </div>
      )}
    </div>
  );
};

export default NavBarSideMenu;
